address {
  font-style: normal;
}

a {
  --tw-text-opacity: 1;
  color: rgb(13 13 13 / var(--tw-text-opacity));
}

a:visited {
  color: #0d0d0d;
}

a.get-touch {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
  text-decoration-line: underline;
}

a.get-touch:visited {
  color: #64748b;
}

.quicklink-navigationbar a {
  --tw-text-opacity: 1;
  color: rgb(245 245 247 / var(--tw-text-opacity));
}

.quicklink-navigationbar a:visited {
  color: #f5f5f7;
}

.quicklink-navigationbar a:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (prefers-color-scheme: dark) {
  .quicklink-navigationbar a:visited {
    color: #f5f5f7;
  }

  .quicklink-navigationbar a:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

footer a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

footer a:visited {
  color: #fff;
}

.scaling-svg-container {
  width: 100%;
  height: 0;
  padding: 0 0 100%;
  position: relative;
}

.scaling-svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

footer {
  --tw-bg-opacity: 1;
  background-color: rgb(13 13 13 / var(--tw-bg-opacity));
}

/*# sourceMappingURL=index.88d1be6e.css.map */
