/*
#BF0426;
#F2B705;
#F2CD5C;
#8C4303;
#0D0D0D;
*/
address {
  font-style: normal;
}
a {
  --tw-text-opacity: 1;
  color: rgb(13 13 13/var(--tw-text-opacity));
}
a:visited {
  color: rgb(13, 13, 13);
}
a.get-touch {
  --tw-text-opacity: 1;
  color: rgb(100 116 139/var(--tw-text-opacity));
  text-decoration-line: underline;
}
a.get-touch:visited {
  color: rgb(100, 116, 139);
}
.quicklink-navigationbar a {
  --tw-text-opacity: 1;
  color: rgb(245 245 247/var(--tw-text-opacity));
}
.quicklink-navigationbar a:visited {
  color: rgb(245, 245, 247);
}
.quicklink-navigationbar a:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}
@media (prefers-color-scheme: dark) {
  .quicklink-navigationbar a:visited {
    color: rgb(245, 245, 247);
  }
  .quicklink-navigationbar a:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
  }
}
footer a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}
footer a:visited {
  color: rgb(255, 255, 255);
}
.scaling-svg-container {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;
  /* override this inline for aspect ratio other than square */
}
.scaling-svg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
footer {
  --tw-bg-opacity: 1;
  background-color: rgb(13 13 13/var(--tw-bg-opacity));
}

